export enum AppLinks {
  homepage = '/',
  newsList = '/novinky',
  matches = '/zapasy',
  match = '/zapas',
  player = '/hrac',
  team = '/tym',
  table = '/tabulka',
  ground = '/hriste',
  club = '/klub',
  stats = '/statistiky',
  statsLeague = '/statistiky/ligy',
  statsTeam = '/statistiky/tymu',
  referee = '/rozhodci',
  offenses = '/offenses',
  search = '/vyhledavani',
  draws = '/losovani',
}

export enum ExternalLinks {
  playerPortal = 'https://portal.malyfotbal.cz',
  emf = 'https://www.eurominifootball.com/',
  wmf = 'http://www.minifootball.com/',
}
