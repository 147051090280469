import { useTranslate } from '@tolgee/react';
import Container from '@amf/shared/components/layout/Container';
import Image from 'next/image';
import IconAppStoreOutline from '@amf/shared/icons/IconAppStoreOutline';
import IconGooglePlayOutline from '@amf/shared/icons/IconGooglePlayOutline';
import { PartnerFragment } from '@amf/shared/types/graphql';
import FooterPartners from '@amf/shared/components/layout/FooterPartners';

import BrandWMF from 'assets/svg/brand-wmf.svg';
import BrandEMF from 'assets/svg/brand-emf.svg';
import { ExternalLinks } from 'utils/links';

type Props = {
  partners: PartnerFragment[];
};

export default function Footer({ partners }: Props) {
  const { t } = useTranslate();
  return (
    <div className='Footer'>
      <Container>
        <h2>{t('footer.partners.title')}</h2>
        <FooterPartners partners={partners} />
        <div className='Footer__row'>
          <div className='Footer__column'>
            <h2>{t('footer.billing.title')}</h2>
            <address>
              Asociace malého fotbalu, z.s.
              <br />
              Roháčova 145/14
              <br />
              130 00 Praha 3
            </address>
            <p>IČ: 26995751</p>
          </div>
          <div className='Footer__column'>
            <h2>{t('footer.contact.title')}</h2>
            <address>
              Asociace malého fotbalu
              <br />
              Peroutkova 570/83, 158 00 Praha 5
            </address>
            <p>
              <a href='mailto:info@malyfotbal.cz'>info@malyfotbal.cz</a>
              <br />
              <a href='tel:+420602725382'>+420 602 725 382</a>
            </p>
          </div>
          <div className='Footer__column'>
            <h2>{t('footer.social.title')}</h2>
            <p>
              <a href='https://www.facebook.com/malyfotbal1' target='_blank' rel='noreferrer'>
                Facebook
              </a>
              <br />
              <a href='https://www.instagram.com/malyfotbal/' target='_blank' rel='noreferrer'>
                Instagram
              </a>
              <br />
              <a
                href='https://www.youtube.com/channel/UC1A-Fqc9Z1uVQL5HEyNMTdg'
                target='_blank'
                rel='noreferrer'
              >
                Youtube
              </a>
            </p>
          </div>
          <div className='Footer__column'>
            <h2>{t('footer.member.title')}</h2>
            <div className='Footer__brands'>
              <a
                href={ExternalLinks.emf}
                target='_blank'
                className='Footer__brandsItem'
                rel='noreferrer'
              >
                <Image src={BrandEMF} alt={'EMF'} layout='fill' />
              </a>
              <a
                href={ExternalLinks.wmf}
                target='_blank'
                className='Footer__brandsItem'
                rel='noreferrer'
              >
                <Image src={BrandWMF} alt={'WMF'} layout='fill' />
              </a>
            </div>
          </div>
        </div>
        <div className='Footer__apps'>
          <h2>{t('footer.apps.title')}</h2>
          <div className='Footer__appsGrid'>
            <a
              href='https://apps.apple.com/vn/app/mal%C3%BD-fotbal/id1665660869?uo=2'
              target='_blank'
              rel='noreferrer'
            >
              <IconAppStoreOutline />
            </a>
            <a
              href='https://play.google.com/store/apps/details?id=com.amf&pli=1'
              target='_blank'
              rel='noreferrer'
            >
              <IconGooglePlayOutline />
            </a>
          </div>
        </div>
      </Container>
    </div>
  );
}
